<template>
  <div class="P-filters">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-input>
    </div>
    <div class="P-input-block G-flex G-align-center P-checkboxes">
      <ott-checkbox
        v-model="filterModel.current"
        @changeCheckbox="filterData('current')"
        label="Current"
      />
      <ott-checkbox
        v-model="filterModel.upcoming"
        @changeCheckbox="filterData('upcoming')"
        class="P-ml-20"
        label="Upcoming"
      />
      <ott-checkbox
        v-model="filterModel.past"
        @changeCheckbox="filterData('past')"
        label="Past"
        class="P-ml-20"
      />
    </div>
    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.date"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        @input="filterData"
        locale="en"
        is-range
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
            <div class="ott-date-picker-cont Ott-input-class:">
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.start"
                        v-on="inputEvents.start"
                        outlined
                        dense
                        height="38"
                        label="Execution Start"
                    />
                    <span
                        class="P-date-icon neutral--text"
                        @click="resetData('date')"
                        :class="
                          !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                    ></span>
                </div>
                <span class="pick-to neutral--text">To</span>
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.end"
                        v-on="inputEvents.end"
                        outlined
                        dense
                        height="38px"
                        label="Execution End"
                    />
                    <span
                        @click="resetData('date')"
                        :class="
                            !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                        class="P-date-icon neutral--text"
                    ></span>
                </div>
            </div>
        </template>
      </DatePicker>
    </div>
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.status"
        :items="discountModule.statusList"
        :persistentHint="true"
        :clear="true"
        @emitFunc="filterData('status')"
        label="Status"
        selected-text="name"
      >
      </ott-select>
    </div>
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.type"
        :items="discountModule.discountTypeList"
        :persistentHint="true"
        :clear="true"
        @emitFunc="filterData('type')"
        label="Discount type"
        selected-text="name"
      >
      </ott-select>
    </div>
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.priceGroup"
        :items="priceGroupAdmin.priceGroupAllList"
        :persistentHint="true"
        :clear="true"
        :isTranslate="true"
        :multiple="true"
        @emitFunc="filterData('priceGroup')"
        label="Price group"
        selected-text="name"
      >
      </ott-select>
    </div>
    <div class="P-input-block">
      <ott-select
        v-model="filterModel.packages"
        :items="packagesList"
        :persistentHint="true"
        :clear="true"
        :multiple="true"
        :isTranslate="true"
        @emitFunc="filterData('packages')"
        label="Packages"
        selected-text="name"
      >
      </ott-select>
    </div>

    <selected-filters-list v-if="filterModel.filterCount.length"/>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import DatePicker from "v-calendar/src/components/DatePicker";
import SelectedFiltersList from "./SelectedFiltersList";

export default {
  name: "FilterData",
  components: { OttSelect, OttCheckbox, OttInput, DatePicker, SelectedFiltersList },
  mixins: [DateFormatMixin, ConvertTimeZoneMixin],
  computed: {
    ...mapState({
      filterModel: (state) => state.discountModule.filterModel,
      filter: (state) => state.discountModule.filterData,
      discountModule: (state) => state.discountModule,
      priceGroupAdmin: (state) => state.priceGroupAdmin,
      packagesList: (state) => state.packagesModule.packagesListForSelect,
    }),
  },
  methods: {
    ...mapActions({
      getDiscountList: "discountModule/getDiscountList",
    }),
    ...mapMutations({
      filterDiscounts: "discountModule/filterDiscounts",
      setFilterPage: 'discountModule/setFilterPage',
      sortColumn: 'discountModule/sortColumn',
    }),

    /**
     * Get price group list
     * **/

    async filterData(type) {
      this.setFilterPage(1)
      this.filterModel.getFilterCount();
      this.filterDiscounts();

      if (!this.filterModel.search && type === "search") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.current && type === "current") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.upcoming && type === "upcoming") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.past && type === "past") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.date?.start && !this.filterModel.date?.end && type === "date") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.status && type === "status") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.type && type === "type") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.priceGroup.length && type === "priceGroup") {
        this.filterModel.resetCurrentData(type);
      }
      if (!this.filterModel.packages.length && type === "packages") {
        this.filterModel.resetCurrentData(type);
      }
      this.sortColumn();
      await this.getDiscountList(this.filter);
      this.$emit("resetSelectedList")
    },
    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterDiscounts();
      this.sortColumn();
      await this.getDiscountList(this.filter);
      this.$emit("resetSelectedList")
    },
  },
};
</script>
<style lang="scss" scoped>
.P-input-block {
  margin-bottom: 20px;

  &:first-child {
    margin-bottom: 10px;
  }
}

.P-ml-20 {
  margin-left: 20px;
}

.P-checkboxes {
  margin-bottom: 10px;
  margin-left: -2px;
}

::v-deep {
  .v-text-field__details,
  .v-messages.theme--light {
    display: none;
  }
}
</style>
