import {mapState, mapMutations, mapActions} from "vuex";
import {required} from "vuelidate/lib/validators";

export default {
  name: "GeneralInfoDiscount",
  data() {
    return {  

    }
  },
  validations: {
    formData: {
      name: { required },
      defaultSalePercent: { required },
      rounding: { required },
      digits: { required },
    },
    discountDate: {
      start: { required },
      end: { required }
    }
  },

  methods: {
    ...mapActions({
      getCheckPriceGroups: 'discountModule/getCheckPriceGroups',

    }),
    ...mapMutations({
      setData: 'generalInfoDiscount/setData',
      setDiscountName: 'generalInfoDiscount/setDiscountName',
      setDiscountType: 'generalInfoDiscount/setDiscountType',
      setDefaultSalePercent: 'generalInfoDiscount/setDefaultSalePercent',
      setRounding: 'generalInfoDiscount/setRounding',
      setDigits: 'generalInfoDiscount/setDigits',
      setIsDisable: 'generalInfoDiscount/setIsDisable',
      setDescription: 'generalInfoDiscount/setDescription',
      setIsSendNotification: 'generalInfoDiscount/setIsSendNotification',
      resetData: 'generalInfoDiscount/resetData',
      setDiscountData: 'discountModule/setDiscountData',
      disableData: 'discountModule/disableData',
    }),

    saveData() {
      this.$v.formData.$touch();
      this.$v.discountDate.$touch();
      if (!this.$v.formData.$error && !this.$v.discountDate.$error) {
        this.setDiscountData({generalInfo: this.formData})
      }
      return this.$v.formData.$error && this.$v.discountDate.$error
    },
    changeStatusDiscount() {
      this.status = !this.status
    },


    discountDateInput() {
      if (this.discountDate?.start && this.discountDate?.end) {
        this.getCheckPriceGroups()
      }
    }
  },
  computed: {
    ...mapState({
      discountModule: state => state.discountModule,
      generalInfoDiscount: state => state.generalInfoDiscount,
      formData: state => state.generalInfoDiscount.formData,
      isLoading: state => state.appModule.isLoading
    }),

    fullDateFormat() {
      return `${this.ottDateFormat} HH:mm`
    },

    name: {
      get() {
        return this.formData.name
      },
      set(value) {
        this.setDiscountName(value)
      }
    },
    type: {
      get() {
        return this.formData.type
      },
      set(value) {
        this.setDiscountType(value)
      }
    },
    discountDate: {
      get() {
        return this.generalInfoDiscount.discountDate
        // if (this.generalInfoDiscount.discountDate?.start && this.generalInfoDiscount.discountDate?.end) {
        //   if (isNaN(new Date(this.generalInfoDiscount.discountDate?.start)) && isNaN(new Date(this.generalInfoDiscount.discountDate?.end))) {
        //     return { 
        //       start: this.$moment(this.generalInfoDiscount.discountDate?.start, this.fullDateFormat).toDate(),
        //       end: this.$moment(this.generalInfoDiscount.discountDate?.end, this.fullDateFormat).toDate(),
        //     }
        //   } else {
        //     return this.generalInfoDiscount.discountDate
        //   }
        // }
      }, 
      set(discountDate) {
        this.setData({ discountDate })
      }
    },
    defaultSalePercent: {
      get() {
        return this.formData.defaultSalePercent
      },
      set(value) {
        this.setDefaultSalePercent(value)
      }
    },
    rounding: {
      get() {
        return this.formData.rounding
      },
      set(value) {
        this.setRounding(value)
      }
    },
    digits: {
      get() {
        return this.formData.digits
      },
      set(value) {
        this.setDigits(+value)
      }
    },
    status: {
      get() {
        return this.formData.status
      },
      set(value) {
        this.setIsDisable(value)
      }
    },
    description: {
      get() {
        return this.formData.description
      },
      set(value) {
        this.setDescription(value)
      }
    },
    sendNotifications: {
      get() {
        return this.formData.sendNotifications
      },
      set(value) {
        this.setIsSendNotification(value)
      }
    }
  },
  watch: {
    status(value) {
      if (typeof value === "number") {
        this.setIsDisable(this.formData.status === 2)
      }
    }

  }
}
