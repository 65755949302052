<template>
  <div class="P-modal-components">
    <TitleComponent title-text="Price Groups"/>
    <p class="P-price-group-subtext neutral--text">Select a price group for a discount</p>
    <div class="P-modal-component-body">
      <div v-if="priceGroup.priceGroupAllList.length" class="P-price-group-list P-manage-client">
        <div class="P-price-group-header">
          <ott-checkbox
              :data-val="priceGroup.priceGroupAllList.length===priceGroupDiscount.discountPriceGroups.length"
              :disabled="discountModule.disableFields"
              label="Select All"
              @changeCheckbox="selectAllList"
          />
        </div>
        <div class="P-price-groups-body G-flex-wrap G-flex">
          <div
              v-for="(item, index) in priceGroup.priceGroupAllList"
              :key="index"
              class="P-price-group-box G-flex G-align-center">
            <ott-checkbox
                :data-val="priceGroupDiscount.discountPriceGroups.includes(item.id)"
                :disabled="discountModule.disableFields || discountModule.checkedPriceGroups.includes(item.id)"
                :label="item.name[0].name"
                @changeCheckbox="selectPriceGroup(item)"
            />
          </div>
          <div v-if="priceGroup.priceGroupAllList.length % 3 === 1" class="P-price-group-box" />
          <div
              v-if="priceGroup.priceGroupAllList.length % 3 === 1 || priceGroup.priceGroupAllList.length % 3 === 2"
              class="P-price-group-box"
          />
        </div>
      </div>
      <div v-if="discountModule.discountId" class="P-save-btn-block G-flex">
        <ott-button-loader
            :isLoading="isLoading"
            button-text="Save"
            class-name="primary-sm-btn"
            @onClick="$emit('editDiscount')"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  // components
  import TitleComponent from "@/components/TitleComponent";
  import SaveResetButtons from "@/components/SaveResetButtons";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

  export default {
    name: 'PriceGroupDiscount',
    components: {
      OttButtonLoader,
      ErrorMessageContainer,
      OttCheckbox,
      SaveResetButtons, TitleComponent
    },
    computed: {
      ...mapState({
        priceGroup: state => state.priceGroupAdmin,
        priceGroupDiscount: state => state.priceGroupDiscount,
        discountModule: state => state.discountModule,
        isLoading: state => state.appModule.isLoading
      }),
      forDefaultPriceGroup: {
        get() {
          return this.discountModule.discountData.forDefaultPriceGroup;
        },
        set(value) {
          this.setDefaultPriceGroup(value);
        }
      }
    },
    methods: {
      ...mapActions({
        getPriceGroupByTypeList: 'priceGroupAdmin/getPriceGroupByTypeList'
      }),
      ...mapMutations({
        generateAllPriceGroups: 'priceGroupAdmin/generateAllPriceGroups',
        updatePriceGroup: 'priceGroupDiscount/updatePriceGroup',
        resetPriceGroupList: 'priceGroupDiscount/resetPriceGroupList',
        selectAllPriceGroupList: 'priceGroupDiscount/selectAllPriceGroupList',
        setDiscountData: 'discountModule/setDiscountData',
        setDefaultPriceGroup: 'discountModule/setDefaultPriceGroup'
      }),

      /**
       * Get price group list
       * **/

      getPriceGroupList() {
        Promise.all([this.getPriceGroupByTypeList({ type: 1 }), this.getPriceGroupByTypeList({ type: 2 })]).then(data => {
          this.generateAllPriceGroups(data)
        })
      },

      saveData() {
        let discountPrices = []

        this.priceGroupDiscount.discountPriceGroups.map(price => {
          if (price !== undefined) {
            if (price !== 'default') {
              discountPrices.push({
                item: price
              })
            }

            if (price === 'default') {
              discountPrices.push({
                item: null
              })
            }
          } else {
            discountPrices.push({})
          }
        })

        this.setDiscountData({priceGroups: discountPrices})
      },

      resetData() {
        this.resetPriceGroupList()
      },

      selectAllList() {
        let newPriceGroups = []
        if (this.discountModule.checkedPriceGroups.length) {
          newPriceGroups = this.priceGroup.priceGroupAllList.filter(x => !this.discountModule.checkedPriceGroups.includes(x.id))
        } else {
          newPriceGroups = this.priceGroup.priceGroupAllList
        }
        this.selectAllPriceGroupList(newPriceGroups)
      },

      selectPriceGroup(item) {
        this.updatePriceGroup(item)
      }
    }
  }
</script>
<style scoped lang="scss">
  @import "../style";

  .G-radio-group {
    margin-top : 0 !important;

    .P-radio-box + .P-radio-box {
      margin-left : 25px;
    }
  }

  .P-general-info {
    margin : 0 -10px;
  }

  .P-price-group-list {
    width         : 100%;
    border        : 1px solid $neutral-color;
    margin-bottom : 25px;
  }

  .P-price-group-header {
    padding       : 5px 10px;
    border-bottom : 1px solid $neutral-color;
  }

  .P-price-group-box {
    width      : 33.33%;
    padding    : 10px;
    border     : 1px solid transparent;
    border-top : 1px solid $neutral-color;
    height     : 53px;

    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      border-top : none;
    }

    &:nth-child(3n + 2) {
      border-right : 1px solid $neutral-color;
      border-left  : 1px solid $neutral-color;
    }
  }

  ::v-deep {
    .v-messages.theme--light {
      display: none;
    }
  }

</style>
