<template>
  <ott-dialog
    v-if="discountModule.modal.isOpen"
    :is-open="discountModule.modal.isOpen"
    :show-footer="false"
    :modal-title="discountModule.discountId ? 'Edit Discount' : 'Add Discount'"
    close-value="addProvider"
    :width="discountModule.activeTab === 9 ? 1120 : 1040"
    @outside="closeModal"
    @resetDataModal="closeModal"
  >
    <template v-slot:modalBody>
      <div class="parent-add-provider-modal">
        <div class="left P-background-neutral7 G-flex-column G-justify-between">
          <div>
            <ul class="list">
              <li
                v-for="(i, index) in discountModule.showList"
                @click="clickList(index)"
                :key="index"
                :class="{
                  listActive: index === discountModule.activeTab,
                  'P-disable-tab': index > discountModule.disableTabs,
                }"
              >
                {{ i.name }}
              </li>
              <li class="P-disable-tab">Notifications</li>
            </ul>
          </div>
          <!--          <ott-button-->
          <!--                  :disabled="!discountModule.discountId"-->
          <!--                  custom-class="primary-sm-btn"-->
          <!--                  text="Save And Close"-->
          <!--                  :click="saveAndCloseData"-->
          <!--          />-->
        </div>
        <div class="right">
          <div class="main">
            <GeneralInfoDiscount
              ref="GeneralInfoDiscount"
              @editDiscount="saveAndCloseData"
              v-if="discountModule.activeTab === 0"
            />
            <PriceGroupDiscount
              ref="PriceGroupDiscount"
              @editDiscount="saveAndCloseData"
              v-if="discountModule.activeTab === 1"
            />
            <PackagesDiscount
              ref="PackagesDiscount"
              @editDiscount="saveAndCloseData"
              v-if="discountModule.activeTab === 2"
            />
            <NotificationsDiscount
              ref="NotificationsDiscount"
              v-if="discountModule.activeTab === 4"
            />

            <Transition name="fade">
              <v-alert
                v-if="isSaved"
                dense
                text
                class="mt-5"
                type="success"
                style="width: max-content"
              >
                The request has been saved successfully
              </v-alert>
            </Transition>
          </div>
          <div class="footer" v-if="!discountModule.discountId">
            <!--            <ott-button-->
            <!--                    custom-class="neutral-md-btn"-->
            <!--                    text="Cancel"-->
            <!--                    :click="closeModal"-->
            <!--            />-->
            <ott-button-loader
              @onClick="nextFunc"
              :is-loading="isLoading"
              :button-text="
                discountModule.activeTab !== 2 ? 'Continue' : 'Save'
              "
              :icon-after="discountModule.activeTab !== 2"
              className="primary-sm-btn P-login-btn"
            />
          </div>
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";
import ManageDiscountMixin from "./ManageDiscountMixin";
import GeneralInfoDiscount from "./generalInfoDiscount/GeneralInfoDiscount";
import PriceGroupDiscount from "./PriceGroupDiscount/PriceGroupDiscount";
import PackagesDiscount from "./PackagesDiscount/PackagesDiscount";
import NotificationsDiscount from "./NotificationsDiscount/NotificationsDiscount";

export default {
  components: {
    NotificationsDiscount,
    PackagesDiscount,
    PriceGroupDiscount,
    GeneralInfoDiscount,
    OttButton,
    OttDialog,
    OttButtonLoader,
  },
  mixins: [ManageDiscountMixin],
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.parent-add-provider-modal {
  display: flex;

  .left {
    min-width: 226px;
    padding: 18px 14px 24px;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    & .list {
      padding-left: 0;
      overflow-y: scroll;
      height: 618px;
      text-transform: capitalize;

      li + li {
        margin-top: 3px;
      }

      & > li {
        padding: 7px 11px;
        cursor: pointer;
        border-radius: 4px;
        font: normal normal 600 14px Segoe UI;
        white-space: nowrap;
        transition: $transition;

        &.P-disable-tab {
          color: $neutral-color;
          cursor: default;
        }

        &:hover:not(.P-disable-tab) {
          background-color: $neutral-color25;
          color: $secondary-color;
        }
      }
    }
  }

  .right {
    padding: 16px 24px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #a5aac6;
      margin: 0 -24px;
      padding: 24px 24px 0;

      & > :nth-child(1) {
        margin-right: 20px;
      }
    }

    ::v-deep {
      .P-modal-component-header {
        margin-bottom: 0;
      }
    }
  }
}
</style>
