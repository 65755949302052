import {mapState, mapMutations} from "vuex";
import {requiredIf, required} from "vuelidate/lib/validators";
import {} from "vuelidate";

export default {
  name: "NotificationDiscount",
  data() {
    return {}
  },
  validations: {
    formData: {
      notificationsMode: {
        required: requiredIf(function () {
          return this.generalInfoDiscount.formData.sendNotifications;
        }),
      },
      beforeDays: {
        required: requiredIf(function () {
          return this.formData.notificationsMode === 'beforeStart';
        }),
      },
      repeatDays: {
        required: requiredIf(function () {
          return this.formData.notificationsMode === 'immediately' || this.formData.notificationsMode === 'beforeStart';
        }),
      },
      notificationTextForUpcoming: {
        required: requiredIf(function () {
          return this.generalInfoDiscount.formData.sendNotifications;
        }),
      },
      notificationTextForCurrent: {
        required: requiredIf(function () {
          return this.generalInfoDiscount.formData.sendNotifications;
        }),
      }
    }
  },

  methods: {
    ...mapMutations({
      setNotificationMode: 'notificationDiscount/setNotificationMode',
      setBeforeDays: 'notificationDiscount/setBeforeDays',
      setRepeatDays: 'notificationDiscount/setRepeatDays',
      setForUpcoming: 'notificationDiscount/setForUpcoming',
      setForClient: 'notificationDiscount/setForClient',
      resetData: 'notificationDiscount/resetData',
      setDiscountData: 'discountModule/setDiscountData',
      setIsSendNotification: 'generalInfoDiscount/setIsSendNotification',

    }),

    saveData() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$error) {
        this.setDiscountData({generalInfo: this.generalInfoDiscount.formData, notifications: this.formData})
      }
      return this.$v.formData.$error
    },
    checkSendNotification() {
      this.setIsSendNotification(true)
    }

  },
  mounted() {

  },
  computed: {
    ...mapState({
      formData: state => state.notificationDiscount.formData,
      notificationDiscount: state => state.notificationDiscount,
      generalInfoDiscount: state => state.generalInfoDiscount,
      discountModule: state => state.discountModule
    }),


    notificationsMode: {
      get() {
        return this.formData.notificationsMode
      },
      set(value) {
        this.setNotificationMode(value)
      }
    },
    beforeDays: {
      get() {
        return this.formData.beforeDays
      },
      set(value) {
        this.setBeforeDays(value)
      }
    },
    repeatDays: {
      get() {
        return this.formData.repeatDays
      },
      set(value) {
        this.setRepeatDays(value)
      }
    },
    notificationTextForUpcoming: {
      get() {
        return this.formData.notificationTextForUpcoming
      },
      set(value) {
        this.setForUpcoming(value)
      }
    },
    notificationTextForCurrent: {
      get() {
        return this.formData.notificationTextForCurrent
      },
      set(value) {
        this.setForClient(value)
      }
    },
  }
}
