import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: 'ManageDiscount',
  mounted() {
    if (this.discountModule.discountId) {
      this.getDiscountDetails(this.discountModule.discountId).then(data => {
        this.setDiscountData(data)
        this.setDataGeneralInfo(data.generalInfo)
        this.setNotificationData(data.notifications)
        this.setPackagesData(data.packages)
        this.setPriceGroupData(data.priceGroups)
        this.disableSaveCloseButton()
        this.disableData()
      })
    }
  },
  methods: {
    ...mapActions({
      getClientList: 'clientsModule/getClientList',
      createDiscount: 'discountModule/createDiscount',
      getDiscountDetails: 'discountModule/getDiscountDetails',
      getDiscountList: 'discountModule/getDiscountList',
      updateDiscount: 'discountModule/updateDiscount',

    }),
    ...mapMutations({
      toggleOpenModal: 'discountModule/toggleOpenModal',
      clickActiveTab: 'discountModule/clickActiveTab',
      nextTab: 'discountModule/nextTab',
      resetDataModal: 'discountModule/resetDataModal',
      resetDataGeneralInfoDiscount: 'generalInfoDiscount/resetData',
      resetDataPackageDiscount: 'packagesDiscount/resetPackageGroupList',
      resetPriceGroupDiscount: 'priceGroupDiscount/resetPriceGroupList',
      notificationDiscount: 'notificationDiscount/resetData',
      setDataGeneralInfo: 'generalInfoDiscount/setDataGeneralInfo',
      setNotificationData: 'notificationDiscount/setNotificationData',
      setPackagesData: 'packagesDiscount/setPackagesData',
      setPriceGroupData: 'priceGroupDiscount/setPriceGroupData',
      disableSaveCloseButton: 'discountModule/disableSaveCloseButton',
      setDiscountData: 'discountModule/setDiscountData',
      disableData: 'discountModule/disableData',
      setDefaultPriceGroup: 'discountModule/setDefaultPriceGroup',
    }),

    closeModal() {
      this.resetDataModal()
      this.resetDataGeneralInfoDiscount()
      this.resetDataPackageDiscount()
      this.resetPriceGroupDiscount()
      this.notificationDiscount()
      this.toggleOpenModal(false)
      this.setDefaultPriceGroup(false)
    },
    clickList(value) {
      if (value <= this.discountModule.disableTabs) {
        switch (this.discountModule.activeTab) {
          case 0: {
            if (!this.$refs['GeneralInfoDiscount'].saveData()) {
              this.changeTab(value)
            }
            break
          }
          case 1: {
            if (!this.$refs['PriceGroupDiscount'].saveData()) {
              this.changeTab(value)
            }
            break
          }
          case 2: {
            if (!this.$refs['PackagesDiscount'].saveData()) {
              this.changeTab(value)
            }
            break
          }
          // case 3: {
          //   if (!this.$refs['NotificationsDiscount'].saveData()) {
          //     this.changeTab(value)
          //   }
          //   break
          // }
          default: {
            this.changeTab(value)
          }
        }
      }


    },
    changeTab(value) {
      if (
        this.discountModule.disableTabs ||
        this.discountModule.disableTabs === 0
      ) {
        if (value <= this.discountModule.disableTabs) {
          this.clickActiveTab(value)
        }
      } else {
        this.clickActiveTab(value)
      }
    },
    async saveAndCloseData() {
      if (this.discountModule.discountId && !this.discountModule.disableFields) {
        if ((this.$refs['GeneralInfoDiscount'] && !this.$refs['GeneralInfoDiscount'].saveData()) ||
          (this.$refs['PriceGroupDiscount'] && !this.$refs['PriceGroupDiscount'].saveData()) ||
          (this.$refs['PackagesDiscount'] && !this.$refs['PackagesDiscount'].saveData())) {
          await this.manageDiscountData()
        }
      }
    },
    async nextFunc() {
      switch (this.discountModule.activeTab) {
        case 0: {
          if (!this.$refs['GeneralInfoDiscount'].saveData()) {
            this.nextTab()
          }
          break
        }
        case 1: {
          if (!this.$refs['PriceGroupDiscount'].saveData()) {
            this.nextTab()
          }
          break
        }
        case 2: {
          if (!this.$refs['PackagesDiscount'].saveData()) {
            await this.manageDiscountData()
          }
          break
        }
        // case 3: {
        //   if (!this.$refs['NotificationsDiscount'].saveData()) {
        //     await this.manageDiscountData()
        //   }
        //   break
        // }
        default: {
          this.nextTab()
        }
      }

    },

    async manageDiscountData() {
      let result = null
      if (!this.discountModule.disableFields) {
        if (this.discountModule.discountId) {
          result = await this.updateDiscount()
        } else {
          result = await this.createDiscount(this.auth.user.provider.id)
          this.closeModal()
        }
        if (result) {
          await this.getDiscountList()
        }
      } else {
        this.closeModal()
      }
    }

  },
  computed: {
    ...mapState({
      discountModule: state => state.discountModule,
      auth: state => state.auth,
      isSaved: state => state.appModule.isSaved,
      isLoading: state => state.appModule.isLoading
    })
  }
}
