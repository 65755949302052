import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      columnConfigData: [],
      defaultColumnConfigData: [],
      actions: [
        { title: 'Edit', value: 'edit' },
        { title: 'Delete', value: 'delete' },
        { title: 'Enable', value: 'enable' },
        { title: 'Disable', value: 'disable' },
        { title: 'Send Notification', value: 'sendNotification' },
      ],
      multipleActions: [
        { title: 'Enable', value: 'enable' },
        { title: 'Disable', value: 'disable' },
        { title: 'Send Notification', value: 'sendNotification' },
      ],
      warningModalBtnText: "",
      warningModalIsDangerButton: true,
      warningModalType: null,
      warningModalIsLoading: false,
      loading: false
    }
  },

  computed: {
    ...mapState({
      filterModel: state => state.discountModule.filterModel,
      packagesList: state => state.packagesModule.dataTable,
      allPackagesCount: state => state.packagesModule.allPackagesCount,
      priceGroupAdmin: state => state.priceGroupAdmin,

      // important variables vor get list and  change  columns
      filterData: state => state.discountModule.filterData,
      filter: state => state.discountModule.filter,
      dataTable: state => state.discountModule.dataTable,
      discountModule: state => state.discountModule,
      defaultSettings: state => state.discountModule.defaultSettings,
      updateColumnSettingsCommit: state => state.discountModule.updateColumnSettingsCommit,
      updateColumnsFromBack: state => state.discountModule.updateColumnsFromBack,
      isDefault: state => state.discountModule.isDefault,
      discountId: state => state.discountModule.discountId
    }),

    defaultColumnConfigs() {
      return [
        {
          title: "Discount Name",
          isTranslate: false,
          style: {minWidth: 160},
          defaultHide: true,
          defaultDragIndex: 0,
          mainIndex: 0,
          key: "name",
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "generalInfo.name",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">${row.generalInfo.name}</p>`
        },
        {
          title: "Start Date",
          isTranslate: false,
          style: {minWidth: 160},
          defaultHide: true,
          defaultDragIndex: 1,
          mainIndex: 1,
          key: "startDate",
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "generalInfo.startDate",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">${row.generalInfo.startDate !== undefined ? row.generalInfo.startDate : '-'}</p>`
        },
        {
          title: "End Date",
          isTranslate: false,
          style: {minWidth: 160},
          defaultHide: true,
          defaultDragIndex: 2,
          mainIndex: 2,
          key: "endDate",
          isShow: true,
          isDrag: true,
          sortData: {
            isSort: true,
            sortKey: "generalInfo.endDate",
            sortValue: ""
          },
          cellView: row => `<p class="G-text-table">${ row.generalInfo.endDate !== undefined ? row.generalInfo.endDate : '-'}</p>`
        },
        {
          title: "Timeline Status",
          isTranslate: false,
          style: {minWidth: 160},
          defaultHide: true,
          defaultDragIndex: 3,
          mainIndex: 3,
          key: "timeLineStatus",
          isShow: true,
          isDrag: true,
          cellView: row => `
            <p class="G-text-table">
              ${
                row?.past ? '<span class="G-badge G-reject-badge G-flex G-align-center">Past</span>' :
                row?.current ? '<span class="G-badge G-approve-badge G-flex G-align-center">Current</span>' :
                row?.upcoming ? '<span class="G-badge G-pending-badge G-flex G-align-center">Upcoming</span>' : '-'
              }
            </p>`
        },
        {
          title: "Type",
          isTranslate: false,
          style: {minWidth: 160},
          defaultHide: true,
          defaultDragIndex: 4,
          mainIndex: 4,
          key: "type",
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${row.generalInfo.type === 2 ? 'Client' : 'Provider'}</p>`
        },
        {
          title: "Price Groups",
          isTranslate: false,
          style: {minWidth: 360},
          defaultHide: true,
          defaultDragIndex: 5,
          mainIndex: 5,
          key: "priceGroups",
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${this.getPriceGroupsList(row.priceGroups)}</p>`
        },
        {
          title: "Status",
          isTranslate: false,
          style: {minWidth: 160},
          defaultHide: true,
          defaultDragIndex: 6,
          mainIndex: 6,
          key: "status",
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table P-time-line-data G-badge ${row.generalInfo.status === 3 ? '' : 'G-disabled-badge'}">${row.generalInfo.status === 3 ? '' : 'Disabled'}</p>`
        },
        {
          title: "Default Sale Percent",
          isTranslate: false,
          style: {minWidth: 160},
          defaultHide: true,
          defaultDragIndex: 7,
          mainIndex: 7,
          key: "defaultSalePercent",
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${row.generalInfo.defaultSalePercent}%</p>`
        },
        {
          title: "Packages",
          isTranslate: false,
          style: {minWidth: 360},
          defaultHide: true,
          defaultDragIndex: 8,
          mainIndex: 8,
          key: "packages",
          isShow: true,
          isDrag: true,
          cellView: row => `<p class="G-text-table">${ row.packages.length === this.allPackagesCount ? 'All Packages' : this.getPackagesNames(row.packages)}</p>`
        },
      ]
    },

    rowsPerPage: {
      set(limit) {
        this.setLimit(limit)
      },
      get() {
        return this.filter.limit;
      }
    },

    warningModalHeading() {
      if (this.warningModalType !== 'sendNotification') {
        return `${this.warningModalBtnText} Discount(s) ?`
      } else {
        return `Send Notification about Discount(s) ?`
      }
    },

    warningModalText() {
      if (this.warningModalType !== 'sendNotification') {
        return `You're about to <span class='text-lowercase'><ins>${this.warningModalBtnText}</ins></span> one or more Discounts! <br />  If you're not sure, you can cancel this operation.`
      } else {
        return `You're about to <ins>send notification</ins> one or more Discounts! <br /><br />  If you're not sure, you can cancel this operation.`
      }
    },
  },

  async created() {
    this.loading = true 
    await Promise.all([
      this.getAllPackages(),
      this.getPriceGroupList(),
      this.getPackagesList(),
      this.getEquipmentsList()
    ]).then(async (data) => {
      await this.getDiscountSettings()
      this.createColumnConfigData()
      this.filterDiscounts()
      this.sortColumn();
      await this.getDiscountsList().finally(() => this.loading = false)
    })
  },

  beforeDestroy() {
    this.filterModel.reset()
    this.setData({ filterData: {} })
  },

  destroyed() {
    this.setFilterPage(1)
  },

  methods: {
    ...mapActions({
      getAllPackages: 'packagesModule/getAllPackages',
      getDiscountList: 'discountModule/getDiscountList',
      getDiscountSettings: 'discountModule/getDiscountSettings',
      getPackagesList: 'packagesModule/getPackagesList',
      getEquipmentsList: 'equipmentsModule/getEquipmentsList',
      getPriceGroupByTypeList: 'priceGroupAdmin/getPriceGroupByTypeList',
      deleteDiscount: 'discountModule/deleteDiscount',
      setNotification: 'discountModule/setNotification',
      enableDisableAction: 'discountModule/enableDisableAction',

    }),
    ...mapMutations({
      setData: 'discountModule/setData',
      toggleOpenModal: 'discountModule/toggleOpenModal',
      setFilterPage: 'discountModule/setFilterPage',
      filterDiscounts: 'discountModule/filterDiscounts',
      sortColumn: 'discountModule/sortColumn',
      generateAllPriceGroups: 'priceGroupAdmin/generateAllPriceGroups',
      setDiscountId: 'discountModule/setDiscountId',
      showWarningModal: 'discountModule/showWarningModal',
      selectDiscounts: 'discountModule/selectDiscounts',
      setIsEnable: 'discountModule/setIsEnable',
      setLimit: 'discountModule/setLimit'
    }),

    async warningModalBtnFunc() {
      this.warningModalIsLoading = true;
      await this.warningModalBtnFunction().finally(() => {
        this.warningModalIsLoading = false;
      });
    },

    async warningModalBtnFunction() {
      switch (this.warningModalType) {
        case "delete":
          await this.deleteDiscountData()
          break;
        case "enable":
          await this.acceptDisableEnable();
          break;
        case "disable":
          await this.acceptDisableEnable();
          break;
        case "sendNotification":
          await this.sendNotificationData();
          break;
      }
    },

    async getDiscountsList(page) {
      if (page) {
        this.setFilterPage(page)
      }
      this.sortColumn()
      await this.getDiscountList(this.discountModule.filterData)
      this.resetSelectedList()
    },

    async updateLimit() {
      await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
        this.createSaveDefaultData();
      })
      this.sortColumn();
      
      await this.getDiscountsList()
    },

    async resetData() {
      this.filterModel.reset();
      this.filterDiscounts()
      await this.getDiscountList(this.filterData)
      this.resetSelectedList()
    },
    openModal() {
      this.toggleOpenModal(true)
    },


    async sortColumnData(data) {
      this.sortColumn(data)
      await this.getDiscountList(this.filterData)
    },

    async resetDataTableSettings() {
      await this.resetDataTable()
      await this.getDiscountSettings()
      this.createColumnConfigData()
    },

    async deleteDiscountData() {
      await this.deleteDiscount().then(async () => {
        this.sortColumn();
        await this.getDiscountsList()
        this.showWarningModal(false)
        this.setDiscountId(null)
      })

    },

    async sendNotificationData() {
      await this.setNotification().then(() => {
        this.resetSelectedList()
        this.showWarningModal(false)
      })
    },

    async acceptDisableEnable() {
      await this.enableDisableAction().then(async () => {
        this.resetSelectedList()
        await this.getDiscountsList(this.filter.page)
        this.showWarningModal(false)
      })
    },

    actionsList(status) {
      let actions = JSON.parse(JSON.stringify(this.actions));
      if (status === 3) {
        actions = actions.filter(item => item.value !== 'enable')
      } else if (status === 2) {
        actions = actions.filter(item => item.value !== 'disable')
      }
      return actions;
    },
    async selectAction() {
      const [event, data, actionName] = arguments;
      const discount = this.getDataFromActions(data);
      const discountId = discount.id;
      this.selectDiscounts([discountId])
      this.setData({ isMultipleAction: false })

      if (actionName !== "edit") {
        this.openWarningModal(actionName)
      } else {
        this.setDiscountId(discountId)
        this.toggleOpenModal(true)
      }

    },

    selectMultipleAction(actionName) {
      const idList = this.selectedArrowList.map(x => x.id)
      this.selectDiscounts(idList)
      this.openWarningModal(actionName)
      this.setData({ isMultipleAction: true })
    },

    openWarningModal(actionName) {
      this.warningModalIsLoading = false;

      switch (actionName) {
        case 'enable': {
          this.setIsEnable(3)
          this.warningModalBtnText = 'Enable';
          this.warningModalIsDangerButton = false;
          this.warningModalType = 'enable'
          break
        }
        case 'disable': {
          this.setIsEnable(2)
          this.warningModalBtnText = 'Disable';
          this.warningModalIsDangerButton = true;
          this.warningModalType = 'disable'
          break
        }
        case 'delete': {
          this.warningModalBtnText = 'Delete';
          this.warningModalIsDangerButton = true;
          this.warningModalType = 'delete'
          break
        }
        case 'sendNotification': {
          this.warningModalBtnText = 'Send';
          this.warningModalIsDangerButton = false;
          this.warningModalType = 'sendNotification'
          break
        }
      }

      this.showWarningModal(true)

    },

    async getPriceGroupList() {
      await Promise.all([this.getPriceGroupByTypeList({ type: 1 }), this.getPriceGroupByTypeList({ type: 2 })]).then(data => {
        this.generateAllPriceGroups(data)
      })
    },

    getPriceGroupsList(data) {
      if (data) {
        let pricesArray = []

        data.map(price => {
          pricesArray.push(price?.item)
        })

        let priceGroups = ''
        let list = this.priceGroupAdmin.priceGroupAllList
          .filter(x => pricesArray.includes(x.value) || x.value === null)
        let defaultPriceExists = pricesArray.includes(undefined)

        if (list.length) {
          list.forEach((item, index) => {
            priceGroups += item.name[0].name + (index !== list.length - 1 ? ', ' : '')
          })
        } else {
          priceGroups = ''
        }
        return priceGroups && defaultPriceExists ? priceGroups + ', ' + 'Default'
                : priceGroups ? priceGroups
                : defaultPriceExists ? 'Default'
                : '-'
      }
    },
    getPackagesNames(data) {
      if (data) {
        let priceGroups = ''
        let list = this.packagesList
          .filter(x => data.includes(x.id))
        if (list.length) {
          list.forEach((item, index) => {
            priceGroups += item.name[0].name + (index !== list.length - 1 ? ', ' : '')
          })
        } else {
          priceGroups = '-'
        }
        return priceGroups
      }
    },

    async exportData() {
      this.filterDiscounts();
      await this.getDiscountList({ ...this.filterData, excel: true })
      this.resetSelectedList()
    }

  }
}
