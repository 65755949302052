<template>
  <div class="P-modal-components">
    <TitleComponent title-text="Notifications"/>
    <div class="P-modal-component-body">
      <div class="P-notifications-block G-flex-wrap G-flex P-manage-client">
        <div class="P-padding-33">
          <ott-select
                  label="Notifications Mode"
                  :items="notificationDiscount.modeList"
                  :error="$v.formData.notificationsMode.$error"
                  :persistentHint="true"
                  selected-text="name"
                  v-model="notificationsMode"
                  @emitFunc="checkSendNotification"
          >
          </ott-select>
        </div>
        <div class="P-padding-33" v-if=" formData.notificationsMode==='beforeStart'">
          <ott-input label="Before Days"
                     v-model="beforeDays"
                     :error="$v.formData.beforeDays.$error"
                     :is-percent="true"
                     :customInputClass="'P-beforeDays'"
          />
        </div>
        <div class="P-padding-33"
             v-if="formData.notificationsMode==='immediately' || formData.notificationsMode==='beforeStart'">
          <ott-input label="Repeat days"
                     v-model="repeatDays"
                     :error="$v.formData.repeatDays.$error"
                     :is-percent="true"
                     :customInputClass="'P-repeatDays'"
          />
        </div>
        <div class="P-padding-100">
          <ott-textarea label="Notification Text For Upcoming"
                        v-model="notificationTextForUpcoming"
                        @emitFunc="checkSendNotification"
                        :error="$v.formData.notificationTextForUpcoming.$error"

          />
        </div>
        <div class="P-padding-100">
          <ott-textarea label="Notification Text For Client"
                        v-model="notificationTextForCurrent"
                        @emitFunc="checkSendNotification"
                        :error="$v.formData.notificationTextForCurrent.$error"

          />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import NotificationDiscountMixin from "./NotificationDiscountMixin";
  import TitleComponent from "@/components/TitleComponent";
  import OttSelect from "@/components/vuetifyComponents/OttSelect";
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import OttTextarea from "@/components/vuetifyComponents/OttTextarea";

  export default {
    components: {OttTextarea, OttInput, OttSelect, TitleComponent},
    mixins: [NotificationDiscountMixin]
  }
</script>
<style scoped lang="scss">
  @import "../style";


</style>
