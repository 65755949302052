<template>
  <div class="P-modal-components">
    <TitleComponent title-text="General Info"/>
    <div class="P-modal-component-body">
      <div class="P-general-info P-manage-client G-flex G-flex-wrap">
        <div class="P-padding-100">
          <v-radio-group v-model="type" class="G-radio-group">
            <div class="G-flex">
              <div class="P-radio-box ">
                <OttRadio
                    v-model="generalInfoDiscount.discountTypeSelect.client"
                    :disabled="discountModule.disableFields"
                    label="Client"
                />
              </div>
              <div class="P-radio-box">
                <OttRadio
                    v-model="generalInfoDiscount.discountTypeSelect.provider"
                    :disabled="discountModule.disableFields"
                    label="Provider"
                />
              </div>
            </div>
          </v-radio-group>
        </div>
        <div class="P-padding-50">
          <ott-input
              v-model="name"
              label="Discount Name*"
              :error="$v.formData.name.$error"
              :disabled="discountModule.disableFields"
          />
        </div>
        <div class="P-padding-50 G-flex G-align-center G-date-picker">
          <!-- <DatePicker
              v-model="startDate"
              :popover="{ visibility: discountModule.disableFields?'': 'click' }"
              :min-date="discountModule.discountId? startDate: newDateWithMainUserTZ"
              :masks="{ input: ottDateFormat }"
              :is-dark="$vuetify.theme.dark"
              locale="en"
              class="ott-date-picker"
              ref="date1"
              @input="setDataStart"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="G-calendar-input">
                <v-text-field
                    v-model="inputValue"
                    v-on="inputEvents"
                    :disabled="discountModule.disableFields"
                    :error="$v.formData.startDate.$error"
                    :readonly="true"
                    label="Start Date*"
                    height="38px"
                    outlined
                    dense
                    @click="date1Func()"
                />
                <span
                    class="P-date-icon neutral--text"
                    :class="'mdi mdi-calendar-blank-outline neutral--text P-calendar'"
                />
              </div>
            </template>
          </DatePicker>
          <span class="P-date-picker-text neutral--text">To</span>
          <DatePicker
              v-model="endDate"
              :popover="{ visibility: discountModule.disableFields?'': 'click' }"
              :min-date="startDate? startDate : newDateWithMainUserTZ"
              :masks="{ input: ottDateFormat }"
              :is-dark="$vuetify.theme.dark"
              locale="en"
              ref="date2"
              class="ott-date-picker"
              @input="setDataEnd"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="G-calendar-input">
                <v-text-field
                    v-model="inputValue"
                    v-on="inputEvents"
                    :disabled="discountModule.disableFields"
                    :error="$v.formData.endDate.$error"
                    :readonly="true"
                    label="End Date*"
                    height="38px"
                    outlined
                    dense
                    @click="date2Func()"
                />
                <span
                    class="P-date-icon neutral--text"
                    :class="'mdi mdi-calendar-blank-outline neutral--text P-calendar'" />
              </div>
            </template>
          </DatePicker> -->


          <DatePicker
            v-model="discountDate"
            :masks="{ input: ottDateFormat }"
            :max-date="newDateWithMainUserTZ"
            :popover="{ visibility: 'click' }"
            :is-dark="$vuetify.theme.dark"
            @input="discountDateInput"
            is-range
            class="ott-date-picker"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="ott-date-picker-cont Ott-input-class:">
                <div class="G-calendar-input">
                  <v-text-field
                    v-model="inputValue.start"
                    v-on="inputEvents.start"
                    :error="$v.discountDate.$error"
                    outlined
                    dense
                    height="38"
                    label="Start Date*"
                  />
                  <span
                    class="P-date-icon neutral--text"
                    @click="discountDate = { start: null, end: null }"
                    :class="
                      !inputValue.start
                        ? 'mdi mdi-calendar-blank-outline P-calendar'
                        : 'icon-cancel'
                    "
                  ></span>
                </div>
                <span class="pick-to neutral--text">To</span>
                <div class="G-calendar-input">
                    <v-text-field
                      v-model="inputValue.end"
                      v-on="inputEvents.end"
                      :error="$v.discountDate.$error"
                      outlined
                      dense
                      height="38px"
                      label="End Date*"
                    />
                    <span
                      @click="discountDate = { start: null, end: null }"
                      :class="
                          !inputValue.start
                          ? 'mdi mdi-calendar-blank-outline P-calendar'
                          : 'icon-cancel'
                      "
                      class="P-date-icon neutral--text"
                    ></span>
                </div>
              </div>
            </template>
          </DatePicker>
        </div>
        <div class="P-padding-33">
          <ott-input
              v-model="defaultSalePercent"
              :error="$v.formData.defaultSalePercent.$error"
              :is-percent="true"
              :customInputClass="'P-default-sale-percent'"
              :disabled="discountModule.disableFields"
              label="Default Sale Percent*"
          />
        </div>
        <div class="P-padding-33">
          <ott-select
              v-model="rounding"
              :items="generalInfoDiscount.discountRoundingList"
              :error="$v.formData.rounding.$error"
              :persistentHint="true"
              :disabled="discountModule.disableFields"
              label="Rounding*"
              selected-text="name"
          />
        </div>
        <div class="P-padding-33">
          <ott-input
              v-model.number="digits"
              :error="$v.formData.digits.$error"
              :is-percent="true"
              :customInputClass="'P-digits'"
              :disabled="discountModule.disableFields"
              label="Digits*"
              type="number"
          />
        </div>
        <div class="P-padding-100">
          <ott-checkbox
              :value="status"
              :disabled="discountModule.disableFields"
              label="Disable"
              @changeCheckbox="changeStatusDiscount"
          />
        </div>
        <div class="P-padding-100">
          <quill-editor 
            v-model="description" 
            :options="{ placeholder: 'Description' }"
            :disabled="discountModule.disableFields"
            :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
          />
        </div>
        <div class="P-padding-100">
          <ott-switch
              v-model="sendNotifications"
              label="Send Notifications"
              :disable="true"
          />
        </div>
        <div class="P-save-btn-block G-flex" v-if="discountModule.discountId">
          <ott-button-loader
              :isLoading="isLoading"
              button-text="Save"
              class-name="primary-sm-btn"
              @onClick="$emit('editDiscount')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import GeneralInfoDiscountMixin from "./GeneralInfoDiscountMixin";
  import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
  import DateFormatMixin from "@/mixins/DateFormatMixin";
  import TitleComponent from "@/components/TitleComponent";
  import OttRadio from "@/components/vuetifyComponents/OttRadio";
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import DatePicker from "v-calendar/src/components/DatePicker";
  import OttSelect from "@/components/vuetifyComponents/OttSelect";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

  export default {
    components: {
      OttButtonLoader,
      OttSwitch, OttCheckbox, OttSelect, OttInput, OttRadio, TitleComponent, DatePicker
    },
    mixins: [DateFormatMixin, GeneralInfoDiscountMixin, ConvertTimeZoneMixin]
  }
</script>
<style scoped lang="scss">
  @import "../style";

  .G-radio-group {
    margin-top : 0 !important;

    .P-radio-box + .P-radio-box {
      margin-left : 25px;
    }
  }

  .P-general-info {
    margin : 0 -10px;
  }


</style>
