<template>
  <selected-filters 
    :selectedList="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  components: { SelectedFilters },
  mixins: [DateFormatMixin],

  computed: {
    ...mapState({
      filterModel: (state) => state.discountModule.filterModel,
      packagesList: (state) => state.packagesModule.dataTable,
      filter: (state) => state.discountModule.filterData,
      discountModule: (state) => state.discountModule,
      priceGroupAdmin: (state) => state.priceGroupAdmin,
    }),

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: "<b>Search</b>: " + this.filterModel.search,
          resetArgument: "search",
        },
        {
          ifCondition: !!this.filterModel.current,
          boxText: "<b>Current</b>: Yes",
          resetArgument: "current",
        },
        {
          ifCondition: !!this.filterModel.upcoming,
          boxText: "<b>Upcoming</b>: Yes",
          resetArgument: "upcoming",
        },
        {
          ifCondition: !!this.filterModel.past,
          boxText: "<b>Past</b>: Yes",
          resetArgument: "past",
        },
        {
          ifCondition: this.filterModel.date?.start && this.filterModel.date?.end,
          boxText: "<b>Date</b>: " + 
            this.getDate(this.filterModel.date?.start) + " - " + 
            this.getDate(this.filterModel.date?.end),
          resetArgument: "date",
        },
        {
          ifCondition: !!this.filterModel.status,
          boxText: "<b>Status</b>: " + this.getFilterDataNames("status"),
          resetArgument: "status",
        },
        {
          ifCondition: !!this.filterModel.type,
          boxText: "<b>Type</b>: " + this.getFilterDataNames("type"),
          resetArgument: "type",
        },
        {
          ifCondition: !!this.filterModel.priceGroup.length,
          boxText: "<b>Price Groups</b>: " + this.getFilterDataNames("priceGroup"),
          resetArgument: "priceGroup",
        },
        {
          ifCondition: !!this.filterModel.packages.length,
          boxText: "<b>Packages</b>: " + this.getFilterDataNames("packages"),
          resetArgument: "packages",
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getDiscountList: "discountModule/getDiscountList",
    }),
    ...mapMutations({
      filterDiscounts: "discountModule/filterDiscounts",
    }),

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterDiscounts();
      await this.getDiscountList(this.filter);
    },
    getFilterDataNames(type) {
      if (type === "status" && this.filterModel.status) {
        return this.discountModule.statusList.filter(
          (x) => x.value === this.filterModel.status
        )[0].name;
      }
      if (type === "type" && this.filterModel.type) {
        return this.discountModule.discountTypeList.filter(
          (x) => x.value === this.filterModel.type
        )[0].name;
      }
      if (type === "priceGroup" && this.filterModel.priceGroup.length) {
        let priceGroupName = "";

        let list = this.priceGroupAdmin.priceGroupAllList.filter((x) =>
          this.filterModel.priceGroup.includes(x.value)
        );
        if (list.length) {
          list.forEach((item, index) => {
            priceGroupName +=
              item.name[0].name + (index !== list.length - 1 ? ", " : "");
          });
        } else {
          priceGroupName = "-";
        }
        return priceGroupName;
      }
      if (type === "packages" && this.filterModel.packages) {
        let packageName = "";
        let list = this.packagesList.filter((x) =>
          this.filterModel.packages.includes(x.value)
        );
        if (list.length) {
          list.forEach((item, index) => {
            packageName +=
              item.name[0].name + (index !== list.length - 1 ? ", " : "");
          });
        } else {
          packageName = "-";
        }
        return packageName;
      }
    },
    getDate(date) {
      return this.$moment(date).format(this.ottDateFormat).valueOf();
    },
  },
};
</script>