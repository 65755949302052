<template>
  <div class="P-modal-components">
    <TitleComponent title-text="Packages"/>
    <p class="P-price-group-subtext neutral--text">Select a packages for a discount</p>
    <div class="P-modal-component-body">
      <div class="P-price-group-list P-manage-client">
        <div class="P-price-group-header">
          <ott-checkbox 
            :data-val="packagesDiscount.selectedPackagesList.length===packagesList.length"
            :disabled="discountModule.disableFields"
            label="Select All"
            @changeCheckbox="selectAllList"
          />
        </div>
        <div class="P-price-groups-body G-flex-wrap G-flex">
          <div v-for="(item, index) in packagesList" :key="index" class="P-price-group-box G-flex G-align-center">
            <ott-checkbox
              :data-val="packagesDiscount.selectedPackagesList.includes(item.id)" 
              :disabled="discountModule.disableFields"
              :label="item.name[0].name"
              @changeCheckbox="selectPackages(item)"
            />
          </div>
          <div v-if="packagesList.length % 3 === 1" class="P-price-group-box" />
          <div 
              v-if="packagesList.length % 3 === 1 || packagesList.length % 3 === 2"
              class="P-price-group-box"
          />
        </div>
      </div>
      <div v-if="discountModule.discountId" class="P-save-btn-block G-flex">
        <ott-button-loader
          :isLoading="isLoading"
          button-text="Save"
          class-name="primary-sm-btn"
          @onClick="$emit('editDiscount')"
        />
      </div>
      <ErrorMessageContainer message="You must select at least one package" v-if="isError"/>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from "vuex";
  // components
  import TitleComponent from "@/components/TitleComponent";
  import SaveResetButtons from "@/components/SaveResetButtons";
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

  export default {
    name: 'PackagesDiscount',
    components: {
      OttButtonLoader,
      ErrorMessageContainer,
      OttCheckbox,
      SaveResetButtons, TitleComponent
    },
    data() {
      return {
        isError: false
      }
    },
    computed: {
      ...mapState({
        packagesDiscount: state => state.packagesDiscount,
        discountModule: state => state.discountModule,
        packagesList: state => state.packagesModule.dataTable,
        isLoading: state => state.appModule.isLoading
      })
    },
    methods: {
      ...mapMutations({
        updatePackageGroup: 'packagesDiscount/updatePackageGroup',
        resetPackageGroupList: 'packagesDiscount/resetPackageGroupList',
        selectAllPackageGroupList: 'packagesDiscount/selectAllPackageGroupList',
        setDiscountData: 'discountModule/setDiscountData'
      }),

      saveData() {
        this.setDiscountData({packages: this.packagesDiscount.selectedPackagesList})
        this.isError = !this.packagesDiscount.selectedPackagesList.length
        return !this.packagesDiscount.selectedPackagesList.length
      },

      resetData() {
        this.resetPackageGroupList()
      },
      selectAllList() {
        this.selectAllPackageGroupList(this.packagesList)
      },

      selectPackages(item) {
        this.updatePackageGroup(item)
        this.isError = false
      }
    }
  }
</script>
<style scoped lang="scss">
  @import "../style";

  .G-radio-group {
    margin-top : 0 !important;

    .P-radio-box + .P-radio-box {
      margin-left : 25px;
    }
  }

  .P-general-info {
    margin : 0 -10px;
  }

  .P-price-group-list {
    width         : 100%;
    border        : 1px solid $neutral-color;
    margin-bottom : 25px;
  }

  .P-price-group-header {
    padding       : 5px 10px;
    border-bottom : 1px solid $neutral-color;
  }

  .P-price-group-box {
    width      : 33.33%;
    padding    : 10px;
    border     : 1px solid transparent;
    border-top : 1px solid $neutral-color;
    height     : 53px;

    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      border-top : none;
    }

    &:nth-child(3n + 2) {
      border-right : 1px solid $neutral-color;
      border-left  : 1px solid $neutral-color;
    }
  }

</style>
