 <template>
  <div class="P-users-list">
    <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }">
      <div class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title">
          <h3 class="secondary--text">Discount</h3>
        </div>
        <ott-button
          v-if="permissionIs('createDiscount')"
          :click="openModal"
          class="primary-sm-btn"
          custom-class="approve"
          icon-before
          text="Add Discount"
          icon-before-class="mdi mdi-plus d-inline"
        />
      </div>
      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <OttCheckbox
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
            <div
              v-if="(selectedArrowList.length || selectedArrowListSub.length) && (permissionIs('deleteDiscount') || permissionIs('sendNotificationAction'))"
              class="approve-rej-icon-cont G-flex G-align-center"
            >
              <v-tooltip
                v-if="permissionIs('deleteDiscount')"
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="P-settings"
                    v-bind="attrs"
                    v-on="on"
                    @click="selectMultipleAction('delete')"
                  >
                    <span class="mdi mdi-delete-outline neutral--text P-icon" />
                  </div>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <v-tooltip
                v-if="permissionIs('sendNotificationAction')"
                top
                class="ott-tooltip"
                color="tooltipBgColor"
                attach=".approve-rej-icon-cont"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    @click="selectMultipleAction('sendNotification')"
                    v-bind="attrs"
                    v-on="on"
                    class="P-settings"
                  >
                    <span class="icon-notification neutral--text P-icon" />
                  </div>
                </template>
                <span>Send Notification</span>
              </v-tooltip>
            </div>
            <div
              v-if="(selectedArrowList.length || selectedArrowListSub.length) && (permissionIs('updateDiscount') || permissionIs('sendNotificationAction'))"
              class="ott-menu select-all-menu-cont P-settings"
            >
              <v-menu offset-y attach=".select-all-menu-cont">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="P-settings"
                    :ripple="false"
                  >
                    <span
                      class="P-icon mdi mdi-dots-vertical G-flex-center"
                      :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}"
                    ></span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(action, index) of multipleActions"
                    :key="index"
                    @click="selectMultipleAction(action.value)"
                  >
                    <v-list-item-title>{{ action.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="G-excel-btn">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="P-settings" v-bind="attrs" v-on="on" @click="exportData">
                    <span class="mdi mdi-file-excel neutral--text P-icon" />
                  </div>
                </template>
                <span>Download Excel</span>
              </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            :column-config="columnConfigData"
            :is-default="isDefault"
            @resetDataSettingsTable="resetDataTableSettings"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            class="P-users-columns"
          />
        </div>
        <div>
          <div
            class="G-filter-btn"
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              v-if="filterModel.filterCount.length > 0"
              class="active-filter-count"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div
        class="G-component-body page-background"
      >
        <div class="G-sub-block-title">
          <h3 class="secondary--text">Discount</h3>
        </div>
        <OttUniversalTable
          v-if="!loading"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="discountModule.dataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :main-data="discountModule.dataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortColumnData"
          @updateColumnWith="updateColumn"
        >
          <template v-if="permissionIs('updateDiscount')" slot-scope="props" slot="action">
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon class="neutral--text">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, i) in actionsList(
                    props.rowData.generalInfo.status
                  )"
                  :key="i"
                  @click="selectAction($event, props, item.value)"
                >
                  <div>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </OttUniversalTable>
        <OttPaginationForTable
          v-model="rowsPerPage"
          v-if="discountModule.discountsResult && !loading"
          :data-count="discountModule.dataCount"
          :available-data-count="discountModule.dataTable.length"
          :current-page="discountModule.currentPage"
          :page-limit="discountModule.totalPages"
          @update="updateLimit"
          @updatePage="getDiscountsList"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      class="G-filter-component page-background"
      :class="{ 'P-filter-open': isOpenFilter }"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
          class="primary-lg-btn"
          text="Reset Filters"
          :click="resetData"
        >
        </ott-button>
      </div>
    </div>
    <PageLoader v-if="discountModule.isTableLoading || loading" />
    <ManageDiscount v-if="discountModule.modal.isOpen" />
    <WarningModal
      v-if="discountModule.modal.showWarningModal"
      :isModalOpen="discountModule.modal.showWarningModal"
      :modalHeading="warningModalHeading"
      :modalText="warningModalText"
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal(false)"
      @btnFunction="warningModalBtnFunc"
    />
  </div>
</template>
<script>
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import FilterData from "./components/FilterData";
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import PageLoader from "@/components/pageLoader/PageLoader";
import DiscountMixin from "./DiscountMixin";
import FilterMixin from "@/mixins/FilterMixin";
import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
import ManageDiscount from "./modals/manageDiscount/ManageDiscount";
import WarningModal from "@/components/WarningModal";
import NoDataInTable from "@/components/NoDataInTable";

export default {
  name: "Discount",
  components: {
    NoDataInTable,
    WarningModal,
    ManageDiscount,
    PageLoader,
    OttPaginationForTable,
    FilterData,
    OttUniversalTable,
    OttHideShowColumnsOption,
    OttCheckbox,
    OttButton,
  },
  mixins: [DiscountMixin, OttUniversalTableForComponentsMixin, FilterMixin, ColumnConfigMixin],
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";

.P-time-line-data {
  border-radius: 30px;
  padding: 0 15px;

  &.P-timeline-past,
  &.P-disable-status {
    background-color: $red-lighter;
    border: 1px solid $red-darker;
    color: $red;
    text-transform: capitalize;
  }

  &.P-timeline-current,
  &.P-enable-status {
    background-color: $green-lighter;
    border: 1px solid $green-darker;
    color: $green;
    text-transform: capitalize;
  }

  &.P-timeline-upcoming {
    background-color: $yellow-lighter;
    border: 1px solid $yellow-darker;
    color: $orange;
    text-transform: capitalize;
  }
}

.theme--dark {
  .icon-no-photos {
    color: $white !important;
  }
}

.P-user-page-image {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
  fill: #0a3c68;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-no-photos {
    color: $neutral-color;
    display: block;
    font-size: 40px;
  }
}

.P-access-users {
  span {
    padding: 3px 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    font-size: $txt14;
  }

  .P-enable-user {
    background-color: $green-lighter;
  }

  .P-disable-user {
    background-color: $danger-color9;
  }
}
</style>
<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";

.P-filter-open {
  .mdi-menu-right {
    transform: rotate(180deg);
  }
}

.P-users-list {
  position: relative;
}

.G-excel-btn {
  border-left: unset;
  padding-left: unset;
}

.action-cont-first {
  border-right: 1.5px solid $neutral-color;
  height: 36px;
  align-items: center;

  .v-menu__content {
    max-width: 190px !important;
  }
}

.P-users-columns::v-deep {
  padding-left: 10px;
  .P-column-configs {
    max-width: 477px;
  }

  .P-column-configs ul li {
    width: 50%;
  }

  .v-menu__content {
    max-height: 360px !important;
  }
}

.P-main-column {
  li {
    background-color: $darkBg-color12MD;
  }
}

.theme--dark {
  .P-users-list::v-deep {
    .P-main-column {
      &:hover {
        li {
          background-color: $darkBg-color12MD !important;
        }
      }
    }
  }
}

.theme--dark.P-actions-list {
  .v-list {
    background-color: #3a3b3f !important;
  }
}
</style>
